<template>
  <div class="invoice" id="invoiceBg">
    <!-- <div class="invoice-btn">
      <van-button type="primary" size="large" to="invoiceEdit"
        >申请发票</van-button
      >
    </div> -->
    <div class="invoice-box">
      <div style="padding: 0.3rem">
        <span>开票记录</span>
      </div>
      <!-- 发票列表 -->
      <!-- 可开票列表 -->
      <div class="invoice-list" v-if="pendList.length > 0">
        <div
          class="invoice-item"
          v-for="(item, index) in pendList"
          :key="index"
        >
          <div class="invoice-item__title">
            <div class="item-title__left">
              <!-- <span>{{item.type}}</span> -->
              <!-- <span class="invoice-type">纸质发票</span> -->
              <van-button type="primary" size="mini" @click="toEdit(item)"
                >申请发票</van-button
              >
            </div>
            <div class="item-title__right" @click="showPopup(item)">
              <span>未开票</span>
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="invoice-item__center">
            <div class="invoice-info">
              <span class="invoice-info__left"> 方案名称</span>
              <span>{{ item.level_name }}</span>
            </div>

            <div class="invoice-info">
              <span class="invoice-info__left">到期时间</span>
              <span>{{ item.valid_time }}</span>
            </div>

            <div class="invoice-info">
              <span class="invoice-info__left">开通时间</span>
              <span>{{ item.open_time }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 已开票列表 -->
      <div class="invoice-list" v-if="applyList.length > 0">
        <div
          class="invoice-item"
          v-for="(item, index) in applyList"
          :key="index"
        >
          <div class="invoice-item__title">
            <div class="item-title__left">
              <span>{{ item.type }}</span>
              <!-- <span class="invoice-type">纸质发票</span> -->
            </div>
            <div class="item-title__right" @click="showPopup(item)">
              <span>已开票</span>
              <van-icon name="arrow" />
            </div>
          </div>

          <div class="invoice-item__center">
            <div class="invoice-info">
              <span class="invoice-info__left"> 方案名称</span>
              <span>{{ item.level_name }}</span>
            </div>

            <div class="invoice-info" v-if="item.tax_type==1 && item.status==1">
              <span class="invoice-info__left">快递名称</span>
              <span style="font-weight: bold">{{ item.express_name }}</span>
            </div>
            <div class="invoice-info" v-if="item.tax_type==2 && item.status == 1">
                <span class="invoice-info__left">电子发票</span>
                <span style="font-weight: bold"><a :href="item.proof" target="_blank">查看电子发票</a></span>
            </div>

            <div class="invoice-info">
              <span class="invoice-info__left">申请时间</span>
              <span>{{ item.add_time }}</span>
            </div>
          </div>

          <!-- <div class="invoice-item__bottom">
            <span class="bold">按订单</span>
            <span class="invoice-preview bold" @click="showPopup">发票预览</span>
          </div> -->
        </div>
      </div>
      <!-- 无数据 -->
      <div v-show="emptyData" class="emptyData">
        <img src="@assets/images/emptyData.png" />
        <p>暂无数据</p>
      </div>
      <van-loading size="24px" v-show="loading">加载中...</van-loading>
    </div>
    <!-- 弹窗 -->
    <van-popup
      v-model="show"
      round 
      closeable
      :style="{ width: '80%', padding: '0.5rem 0' }"
    >
      <div class="invoice-item__center">
        <div class="invoice-info" v-if="applyItem.tax_type==1 && applyItem.status==1">
          <span class="invoice-info__left">快递单号</span>
          <span>{{ applyItem.express_number }}</span>
        </div>
        <div class="invoice-info" v-if="applyItem.tax_type==2 && applyItem.status==1">
            <span class="invoice-info__left">电子发票</span>
            <span><a :href="applyItem.proof" target="_blank">查看电子发票</a> </span>
        </div>
        <div v-if="applyItem.catid == '公司'">
          <div class="invoice-info">
            <span class="invoice-info__left">企业税号</span>
            <span>{{ applyItem.number }}</span>
          </div>
          <div class="invoice-info">
            <span class="invoice-info__left">开户银行</span>
            <span style="font-weight: bold">{{ applyItem.bank_name }}</span>
          </div>
        </div>

        <div class="invoice-info" v-if="applyItem.catid == '个人'">
          <span class="invoice-info__left">身份证号</span>
          <span style="font-weight: bold">{{ applyItem.id_card }}</span>
        </div>

        <div class="invoice-info">
          <span class="invoice-info__left">联系地址</span>
          <span style="font-weight: bold">{{
            applyItem.receiver_address
          }}</span>
        </div>
        <div class="invoice-info">
          <span class="invoice-info__left">联系电话</span>
          <span style="font-weight: bold">{{ applyItem.receiver_mobile }}</span>
        </div>

        <div class="invoice-info">
          <span class="invoice-info__left">申请时间</span>
          <span>{{ applyItem.add_time }}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import Loading from "@components/Loading";
import Vue from "vue";
import { invoiceList } from "@api/user";
import { Button, Icon, Popup, Loading } from "vant";

Vue.use(Loading);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Icon);

export default {
  data() {
    return {
      loading: true,
      show: false,
      applyList: [],
      pendList: [],
      applyItem: {},
      address: {},
      emptyData: false,
    };
  },

  created() {
    this.getInvoiceList();
  },
  methods: {
    // 获取发票列表
    getInvoiceList() {
      invoiceList()
        .then((res) => {
          if(res.status != 200) return this.$dialog.error(res.msg);
          this.loading = false;
          this.applyList = res.data.apply_tax_list;
          this.pendList = res.data.pend_tax_list;
          this.address = res.data.address;
          if(this.applyList.length == 0 && this.pendList.length == 0) {
            this.emptyData = true;
            return;
          }
          this.emptyData = false;
        })
        .catch((err) => {});
    },
    // 申请发票
    toEdit(item) {
      this.$router.push({
        name: "user-invoiceEdit",
        params: { id: item.level_id, address: JSON.stringify(this.address) },
      });
    },
    showPopup(item) {
      this.show = true;
      this.applyItem = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice-box {
  // 发票列表
  .invoice-list {
    .invoice-item {
      background-color: #ffffff;
      margin-bottom: 10px;
      // 标题
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.3rem;
        border-bottom: 1px solid #cccccc;
        .item-title__left {
          .invoice-type {
            background-color: cornflowerblue;
            color: #ffffff;
            border-radius: 4px;
            padding: 4px 10px;
            margin-left: 10px;
          }
        }
        .item-title__right {
          display: flex;
          align-items: center;
        }
      }
      // 发票信息
      &__center {
        padding: 0.1rem 0.3rem;
        border-bottom: 1px solid #cccccc;
        .invoice-info {
          padding: 0.1rem 0;
          &__left {
            margin-right: 20px;
          }
        }
      }
      // 底部
      &__bottom {
        padding: 0.2rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //   发票预览
        .invoice-preview {
          border: 1px solid #c5c5c5;
          padding: 0.1rem 0.3rem;
          border-radius: 4px;
        }
      }
    }
  }
  .van-loading {
    display: flex;
    justify-content: center;
  }
}
// 弹窗
.invoice-item__center {
  padding: 0.1rem 0.3rem;

  .invoice-info {
    padding: 0.1rem 0;
    &__left {
      margin-right: 20px;
    }
  }
}
</style>